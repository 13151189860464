import { Component, OnInit } from '@angular/core';
import {DataServiceService} from '../data-service.service';

@Component({
  selector: 'app-topic-list',
  templateUrl: './topic-list.component.html',
  styleUrls: ['./topic-list.component.css']
})
export class TopicListComponent implements OnInit {

  
  mdata = [];

  constructor(private user:DataServiceService)
  {
    //console.warn(this.user.getMCQData())
    //let data = this.user.getMCQData();
    //this.name = data.id;
    this.user.getMCQData().subscribe(data=>{
      console.warn(data);
      this.mdata = <any>data;
    })
  }

  ngOnInit(): void {
  }

}
