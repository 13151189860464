import { Component } from '@angular/core';
import {DataServiceService} from './data-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ugoodsmcq';
  name = "";
  mdata = [];

  constructor(private user:DataServiceService)
  {
    //console.warn(this.user.getMCQData())
    //let data = this.user.getMCQData();
    //this.name = data.id;
    this.user.getMCQData().subscribe(data=>{
      console.warn(data);
      this.mdata = <any>data;
    })
  }
}
