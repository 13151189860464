import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { ActivatedRoute, Params } from '@angular/router';
import {DataServiceService} from '../data-service.service';


@Component({
  selector: 'app-mcq-list',
  templateUrl: './mcq-list.component.html',
  styleUrls: ['./mcq-list.component.css']
})
export class McqListComponent implements OnInit {

  rid="0"
  myanswer=""

  mdata = [];

  constructor(private route: ActivatedRoute, private user:DataServiceService) {
    this.rid = this.route.snapshot.params['id'];

    this.user.getMCQ(this.rid).subscribe(data=>{
      console.warn(data);
      this.mdata = <any>data;
    })
  }
  ans='';
  checkit(b:string, x:any, y:any,z:any){
  
    if(x==y){
      this.ans=b+'right'+z;
    }else{
      this.ans=b+'wrong'+z;
    }
  }

  ngOnInit(){}

}
