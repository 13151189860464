<table class="table">
    <tr>
      <td>Name</td>
      <td>Attempt</td>
    </tr>
    <tr *ngFor="let item of mdata">
      <td style="width: 80%;"><b>{{ item['name'] }}</b>
      <br/><small>{{ item['description'] }}</small></td>
      <td><a class="btn btn-warning" routerLink="/mcq-list/{{ item['id'] }}">Attempt</a></td>
    </tr>
</table>
