import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  constructor(private http:HttpClient) {

  }

  getMCQData(){
    let url = "http://ugoods.in/tutorials/api/api-mcqtopic.php";
    return this.http.get(url);
  }

  getMCQ(id: String){
    let url2 = "http://ugoods.in/tutorials/api/api-mcq.php?topicid="+id;
    return this.http.get(url2);
  }

  getData()
  {
    return {
      name: 'Rehan',
      dob: '20-06-1996'
    }
  }
}
